<template>
    <modal ref="modalCancelarPedido" titulo="Cancelar pedido" no-aceptar cancelar="Cerrar" :adicional="boton" @adicional="actionButton">
        <template v-if="isCancelable">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 j-center">
                    <div class="col-10 mt-4">
                        <p class="text-general f-12 pl-3">
                            Motivo de cancelación
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Motivo de cancelación">
                            <el-select v-model="motivo" class="w-100">
                                <el-option
                                v-for="item in motivos"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-10 mt-4">
                        <p class="text-general f-12 pl-3">
                            Dinos porque cancelas el pedido
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Comentarios">
                            <el-input v-model="justificacion" class="br-12" placeholder="Deja tu comentario" type="textarea" :rows="6" maxlength="250" show-word-limit />
                            <span v-if="errors[0]" class="text-danger w-100 f-11">Es necesario que dejes un comentario</span>
                        </ValidationProvider>
                    </div>
                </div>
            </validationobserver>
        </template>
        <template v-else>
            <p class="font-omnes f-40 text-center text-orange">
                !Aguarda¡
            </p>
            <div class="row mx-0 j-center my-3">
                <img :src="funImagenGeneral(idImagenCancelacion)" style="max-width:100%;height: 320px;" />
            </div>
            <div class="row mx-0 j-center mt-3">
                <p class="col-10 text-black f-15">
                    <i class="f-600">¡Lo sentimos!</i>  <br /> El pedido no puede ser cancelado en este punto de la operación.<br /> Por favor comuniquese con el {{ $config.vendedor }}.
                </p>
            </div>
        </template>
    </modal>
</template>

<script>
import Service from '~/services/pedidos/pedidos'

export default {
    data(){
        return {
            isCancelable:true,
            idImagenCancelacion:85,
            pedido: {},
            motivos: [],
            motivo: null,
            justificacion: null,
            boton: 'Cancelar'
        }
    },
    computed:{
        idPedido(){
            return Number(this.$route.params.idPedido)
        }
    },
    methods: {
        async toggle(pedido){
            this.isCancelable = true
            this.pedido = pedido;
            await this.getMotivos();
            this.clear();
            this.$refs.modalCancelarPedido.toggle();
        },

        async getMotivos(){
            try {
                let tipo = 2;
                const {data} = await Service.getMotivos(tipo);
                this.motivos = data.motivos;
            } catch(e){
                this.errorCatch(e);
            }
        },
        async actionButton(){
            if(!this.isCancelable){
                this.goChat();
            } else {
                this.postCancelarPedido();
            }
        },

        async postCancelarPedido(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid) return;

                const model = {
                    idPedido: this.idPedido,
                    idMotivo: this.motivo,
                    justificacion: this.justificacion
                };

                const {data} = await Service.postCancelarPedido(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('update');
                this.clear();
                this.$refs.modalCancelarPedido.toggle();
            } catch(e){
                const dataError = e.response.data
                if(dataError.idImg){
                    this.idImagenCancelacion = dataError.idImg
                    this.isCancelable = false
                    this.boton = "Ir al Chat"
                }else {
                    this.errorCatch(e);
                }
            }
        },

        async goChat(){
            this.$refs.modalCancelarPedido.toggle();
            this.$emit('goChatPedido');
        },

        async clear(){
            
            this.boton = "Cancelar"
            this.motivo = null;
            this.justificacion = null;
            await this.$refs.validacion.reset();
        },
    }
}
</script>
5